import React from "react";
import "./industries.css";
import agriculture from "../../assets/agriculture.jpg";
import banking from "../../assets/banking.jpg";
import goods from "../../assets/goods.jpg";
import government from "../../assets/government.jpg";
import healthcare from "../../assets/healthcare.jpg";
import logistics from "../../assets/logistics.jpg";
import manufacturing from "../../assets/manufacturing.jpg";
import realestate from "../../assets/realestate.jpg";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";
function Industries() {
  let navigate = useNavigate();
  return (
    <div>
      <div className="industry_intro">
        <div className="industry_title">Industry Expertise</div>
        <div className="industry_detail">
          At Eskeym, we create an environment where motivated, innovative people
          like you can thrive. This is the right place for you to help your
          career and organizations grow better. We'll get in touch when
          something opens up.
        </div>

        <div className="button_main">
          <button
            className="btn btn-dark button_get_in_touch"
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Get In Touch
          </button>
        </div>
      </div>

      <div className="industry_main row">
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">
              Banking & Financial Services
            </div>
            <div className="industry_detail_left_text">
              Banking & Financial Services are the lifeblood of any economy.
              They facilitate monetary circulation, aiding underserved
              communities. Today, the industry thrives on digitization,
              cutting-edge tech, and customer-centricity.
              <br></br>
              <br></br>
              At ESKEYM Consulting, we prioritize three key aspects:
              Customer-Centric Enterprises, Robust Enterprise Risk Management,
              and Innovation with Cost Efficiency. Our solutions empower banks
              to swiftly embrace digitalization and online banking, accelerating
              project delivery, optimizing cash flow, and elevating
              business outcomes.
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={banking}
            width={"100%"}
            style={{ backgroundColor: "#0083728b" }}
          ></img>
        </div>
      </div>
      <div className="industry_main row">
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={healthcare}
            width={"100%"}
            style={{ backgroundColor: "#1f457e8b" }}
          ></img>
        </div>
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">Healthcare</div>
            <div className="industry_detail_left_text">
              Healthcare stands as India's largest revenue and employment
              sector, thriving due to the surge in public and private players,
              embracing early digitalization and technology. This expansive
              sector encompasses pharmaceuticals, biotechnology, equipment,
              distribution, facilities, and managed health care.
              <br></br>
              <br></br>
              At Eskeym Solutions, we provide an open, secure, and
              enterprise-grade environment tailored to the dynamic healthcare
              industry's needs. Our seasoned consultants enhance efficiency,
              resilience, and performance, ensuring optimal uptime, research
              advancement, and compliance adherence. Partner with us to
              streamline and fortify your healthcare operations, driving
              progress in this ever-evolving landscape.
            </div>
          </div>
        </div>
      </div>
      <div className="industry_main row">
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">
              Real Estate Services
            </div>
            <div className="industry_detail_left_text">
              Real Estate has witnessed a transformation propelled by IT
              excellence. Our service-based company serves as the catalyst for
              your property endeavors, boasting a proven track record of success
              in Real Estate projects. With a foundation in comprehensive
              research and IT expertise, we deliver tailored solutions that
              optimize property management, data analysis, and customer
              engagement, ensuring your real estate business ascends to new
              heights through the adoption of cutting-edge technology.
              <br></br>
              <br></br>
              Experience the future of real estate, where IT innovation and
              strategic excellence converge to reshape the industry landscape.
              Our solutions are customized for your success, promising to
              elevate your property ventures and provide a competitive edge in
              today's dynamic market.
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={realestate}
            width={"100%"}
            style={{ backgroundColor: "#afa1f38b" }}
          ></img>
        </div>
      </div>
      <div className="industry_main row">
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={goods}
            width={"100%"}
            style={{ backgroundColor: "#f1bd6c8b" }}
          ></img>
        </div>
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">
              Consumer Goods Services
            </div>
            <div className="industry_detail_left_text">
              In the fast-paced world of Consumer Goods Service, experience is
              key, and our consulting services bring a wealth of it to the
              table. With a deep understanding of the Consumer Goods sector, we
              offer tailored strategies that are data-driven to perfection. Our
              aim is to streamline operations, supercharge market presence, and
              ultimately, ensure your triumph in these fiercely competitive
              industries.
              <br></br>
              <br></br>
              Unlock the full potential of your consumer goods business with
              strategies honed through experience and data-driven precision.
              We're here to help you navigate the challenges of these dynamic
              industries, making certain that your business not only
              survives but thrives.
            </div>
          </div>
        </div>
      </div>
      <div className="industry_main row">
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">Manufacturing</div>
            <div className="industry_detail_left_text">
              Manufacturing stands as one of the globe's swiftest expanding
              industries, focusing on the production of various goods through
              techniques like casting, moulding, and machining. A
              cost-effective, digital approach is now essential to ensure
              seamless supply chain operations and deliver top-quality products.
              <br></br>
              <br></br>
              With Eskeym Consulting, embrace automation through our integrated
              AI, hybrid cloud, and automation solutions. Harness real-time
              industrial data for informed decision-making in supply chain and
              operations management, enabling the establishment of smart
              factories. The result? Enhanced productivity, performance,
              reliability, transparency, and sustainability. Keep your
              operations at peak efficiency, adapt to evolving customer demands,
              and stay at the forefront of manufacturing excellence.
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={manufacturing}
            width={"100%"}
            style={{ backgroundColor: "#5da2838b" }}
          ></img>
        </div>
      </div>
      <div className="industry_main row">
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={agriculture}
            width={"100%"}
            style={{ backgroundColor: "#f9aaf08b" }}
          ></img>
        </div>
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">Agriculture</div>
            <div className="industry_detail_left_text">
              The agriculture industry is a cornerstone of India's economy,
              employing the majority of its population and contributing
              significantly to the GDP. As the world's leading agricultural
              producer by revenue, it presents vast opportunities. With shifting
              consumer preferences towards organic, nutrient-rich foods, the
              sector is evolving.
              <br></br>
              <br></br>
              ESKEYM Consulting offers solutions to enhance your agricultural
              venture, boosting profits and harvest yields. Leveraging satellite
              imagery, weather data, and equipment insights, you can optimize
              crop cultivation per hectare. Our intelligent IT solutions empower
              agricultural regeneration, improving both quality and quantity,
              while meeting the demands of a changing market.
            </div>
          </div>
        </div>
      </div>
      <div className="industry_main row">
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">Government</div>
            <div className="industry_detail_left_text">
              In the past, government offices often relied on outdated manual
              processes, failing to keep pace with the tech-savvy demands of the
              modern era. Recognizing the need for digital transformation,
              government institutions have taken steps to modernize their
              operations. At Eskeym, we're committed to advancing this
              transformation through our cutting-edge IT solutions.
              <br></br>
              <br></br>
              Today, citizens expect a seamless experience when engaging with
              public services, an expectation that traditional government
              offices struggled to fulfill. However, the landscape is evolving,
              as government agencies increasingly prioritize data-driven
              decision-making and the delivery of citizen-centric products and
              services. This is the opportune moment to introduce management
              systems that enhance efficiency and drive results, setting a new
              standard for government operations that meet the needs of
              the digital age.
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={government}
            width={"100%"}
            style={{ backgroundColor: "#f46b6c8b" }}
          ></img>
        </div>
      </div>
      <div className="industry_main row">
        <div className="col-lg-6 col-md-5 col-sm-12 industry_detail_image">
          <img
            src={logistics}
            width={"100%"}
            style={{ backgroundColor: "#4674cf8b" }}
          ></img>
        </div>
        <div className="industry_detail_left col-lg-6 col-md-7 col-sm-12">
          <div className="industry_detail_left_submain">
            <div className="industry_detail_left_title">
              Travel and Logistics
            </div>
            <div className="industry_detail_left_text">
              Transform Travel and Logistics with Our Data-Driven Expertise. Our
              research-backed services encompass the entire spectrum of
              transportation, logistics, and supply chain management. Leveraging
              cutting-edge data analysis, we not only optimize routes and
              streamline operations but also enhance overall efficiency
              throughout the journey.
              <br></br>
              <br></br>
              Our team understands the intricate dynamics of the industry,
              enabling us to offer tailor-made solutions that resonate with your
              business's unique requirements. By partnering with us, you're
              embracing the opportunity to navigate the future of travel and
              logistics with confidence and success, as we keep you at the
              forefront of this ever-evolving sector.
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Industries;

import React from "react";
import "./careers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFaceLaughBeam } from "@fortawesome/free-regular-svg-icons";
import Footer from "../footer/footer";
import mission from "../../assets/mission.svg";
import collab from "../../assets/collab.svg";
import corevalues from "../../assets/corevalues.webp";
import { faAward, faGears, faToggleOff, faUsersGear } from "@fortawesome/free-solid-svg-icons";
function Careers() {
  return (
    <div>
      <div className="careers_intro">
        <div className="careers_title">Our Roadmap To A Bright Future</div>
        <div className="careers_detail">
          At Eskeym, we create an environment where motivated, innovative people
          like you can thrive. This is the right place for you to help your
          career and organizations grow better. We'll get in touch when
          something opens up.
        </div>

        <div className="careers_opening_detail">
          To know opening with us, Please share your resume at{" "}
          <b>careers@eskeym.com</b> and our HR team will get back you shortly.
        </div>
      </div>
      <div className="careers_perks">
        <div className="careers_perks_title">Our perks</div>
        <div className="careers_perks_detail">
          At Eskeym, we belive in keeping our employees happy.
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="careers_perks_content">
              <FontAwesomeIcon
                icon={faClock}
                className="careers_perks_content_icon"
              ></FontAwesomeIcon>
              <div className="careers_perks_content_title">
                {" "}
                Flexible Work Arrangements
              </div>
              <div className="careers_perks_content_details">
                Customize your work schedule for a better work-life balance.
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="careers_perks_content">
              <FontAwesomeIcon
                icon={faGears}
                className="careers_perks_content_icon"
              ></FontAwesomeIcon>
              <div className="careers_perks_content_title">
                Development Opportunities
              </div>
              <div className="careers_perks_content_details">
                Grow your skills with ongoing training and career advancement.
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="careers_perks_content">
              <FontAwesomeIcon
                icon={faUsersGear}
                className="careers_perks_content_icon"
              ></FontAwesomeIcon>
              <div className="careers_perks_content_title">
                Team-building Activities
              </div>
              <div className="careers_perks_content_details">
                Foster a positive workplace through engaging team events.
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="careers_perks_content">
              <FontAwesomeIcon
                icon={faFaceLaughBeam}
                className="careers_perks_content_icon"
              ></FontAwesomeIcon>
              <div className="careers_perks_content_title">
                Well-being <br></br>Support
              </div>
              <div className="careers_perks_content_details">
                Access resources for mental and physical well-being.
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="careers_perks_content">
              <FontAwesomeIcon
                icon={faToggleOff}
                className="careers_perks_content_icon"
              ></FontAwesomeIcon>
              <div className="careers_perks_content_title">
                Generous Time Off Policies
              </div>
              <div className="careers_perks_content_details">
                Enjoy ample vacation and paid time off for a balanced lifestyle.
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="careers_perks_content">
              <FontAwesomeIcon
                icon={faAward}
                className="careers_perks_content_icon"
              ></FontAwesomeIcon>
              <div className="careers_perks_content_title">
                Recognition and Rewards
              </div>
              <div className="careers_perks_content_details">
                Be acknowledged for your work through rewards and recognition.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="careers_culture row">
        <div className="careers_culture_title">Culture</div>
        <div className="careers_culture_text">
          Exceptional companies may not build a great culture for employees, but
          an exceptional culture consistently creates an exceptional company!
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 careers_culture_content">
          <img src={mission} width={"30%"} style={{maxWidth: "100px"}}></img>
          <div className="careers_culture_content_title">Empowering Growth</div>
          <div>
            Eskeym's global team excels in delivering top-notch enterprise
            solutions and IT consultancy, empowering clients for business
            growth.
          </div>
          <ul>
            <li>
              We innovate to exceed industry standards, keeping clients at the
              forefront of technology.
            </li>
            <li>
              Committed to continuous learning, we embrace a culture of ongoing
              improvement.
            </li>
            <li>
              Tailoring services to unique client needs ensures satisfaction and
              success.
            </li>
            <li>
              Our rigorous standards ensure reliability and excellence in every
              solution.
            </li>
            <li>
              Fostering teamwork, our culture amplifies our pursuit of
              excellence for the benefit of both our team and clients.
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 careers_culture_content">
          <img src={collab} width={"30%"} style={{maxWidth: "100px"}}></img>
          <div className="careers_culture_content_title">
            Global Collaboration and Integration
          </div>
          <div>
            Eskeym's global team delivers top-tier solutions and IT consultancy,
            emphasizing collaboration for global integration.
          </div>
          <ul>
            <li>
              We seamlessly integrate solutions, keeping clients globally
              connected and technologically advanced.
            </li>
            <li>
              Rooted in ongoing collaboration, our culture values collective
              learning and improvement.
            </li>
            <li>
              Services are customized for diverse global needs, ensuring
              satisfaction and success worldwide.
            </li>
            <li>
              Upholding stringent global standards, we ensure reliability and
              excellence in every solution.
            </li>
            <li>
              Our collaborative team culture propels excellence globally,
              benefiting both our team and clients internationally.
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 careers_culture_content">
          <img src={corevalues} width={"30%"} style={{maxWidth: "100px"}}></img>
          <div className="careers_culture_content_title">
            Client-Centric Trustworthiness
          </div>
          <div>
            Eskeym's global team delivers premium solutions, emphasizing
            client-centric trustworthiness for enduring partnerships.
          </div>
          <ul>
            <li>
              Innovation prioritizes exceeding industry standards, keeping
              clients technologically ahead.
            </li>
            <li>
              Rooted in commitment, our culture ensures ongoing learning and
              improvement to better serve clients.
            </li>
            <li>
              Meticulously tailored services address unique client needs,
              ensuring satisfaction and success.
            </li>
            <li>
              Upholding strict standards, we guarantee reliability and
              excellence, fostering trust in client relationships.
            </li>
            <li>
              Our collaborative team culture ensures excellence, benefiting both
              our team and building trust and success for our clients.
            </li>
          </ul>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Careers;

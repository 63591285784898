import React, { useEffect, useState } from "react";
import "./whatWeDoStyles.css";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";
import softwareDevJson from "./json/softwareDev.json";
import bigDataJson from "./json/bigData.json";
import operationsJson from "./json/operations.json";
import productDevJson from "./json/productDev.json";
import salesJson from "./json/sales.json";
import techopsJson from "./json/techops.json";
function WhatWeDo(props) {
  let navigate = useNavigate();
  let [data, setData] = useState(softwareDevJson);
  useEffect(() => {
    if (props.type == "software") {
      setData(softwareDevJson);
    } else if (props.type == "bigdata") {
      setData(bigDataJson);
    } else if (props.type == "techops") {
      setData(techopsJson);
    } else if (props.type == "sales") {
      setData(salesJson);
    } else if (props.type == "ops") {
      setData(operationsJson);
    } else if (props.type == "product") {
      setData(productDevJson);
    }
  }, [props.type]);

  return (
    <div>
      <div className="whatWeDo_intro">
        <div className="whatWeDo_title">{data.name}</div>
        <div className="whatWeDo_detail">{data.header}</div>

        <div className="button_main">
          <button
            className="btn btn-dark button_get_in_touch"
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Get In Touch
          </button>
        </div>
      </div>
      <div>
        <div className="whatWeDo_perks">
          <div className="whatWeDo_perks_detail">{data.title}</div>
          {data.data.map((elem, index) => (
            <>
              <div className="whatWeDo_perks_title">{elem.title}</div>
              {elem.data.map((elem_detail, elem_detail_index) => (
                <div className="whatWeDo_detail" key={elem_detail_index}>
                  <div className="whatWeDo_perks_detail_main">
                    {elem_detail.title}
                  </div>
                  {elem_detail.data.map(
                    (elem_detail_data, elem_detail_data_index) => (
                      <div
                        className="whatWeDo_perks_detail_item"
                        key={elem_detail_data_index}
                      >
                        - {elem_detail_data}
                      </div>
                    )
                  )}
                </div>
              ))}
            </>
          ))}

          {data.solutions && (
            <>
              <div className="whatWeDo_perks_title">{data.solutions.title}</div>
              {data.solutions.data.map((elem_detail, elem_detail_index) => (
                <div className="whatWeDo_detail" key={elem_detail_index}>
                  <div className="whatWeDo_perks_detail_main">
                    {elem_detail.title}
                  </div>
                  {elem_detail.data.map(
                    (elem_detail_data, elem_detail_data_index) => (
                      <div
                        className="whatWeDo_perks_detail_item"
                        key={elem_detail_data_index}
                      >
                        - {elem_detail_data}
                      </div>
                    )
                  )}
                </div>
              ))}
            </>
          )}
          <div className="whatWeDo_perks_title">{data.whyUs.title}</div>
          {data.whyUs.data.map((elem_detail, elem_detail_index) => (
            <div className="whatWeDo_detail" key={elem_detail_index}>
              <div className="whatWeDo_perks_detail_main">
                {elem_detail.title}
              </div>
              {elem_detail.data.map(
                (elem_detail_data, elem_detail_data_index) => (
                  <div
                    className="whatWeDo_perks_detail_item"
                    key={elem_detail_data_index}
                  >
                    - {elem_detail_data}
                  </div>
                )
              )}
            </div>
          ))}
          <div className="whatWeDo_perks_title">{data.partnerWithUs.title}</div>
          <div className="whatWeDo_detail">
            <div className="whatWeDo_perks_detail_main">
              {data.partnerWithUs.data}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default WhatWeDo;

import { Outlet, useLocation } from "react-router-dom";
import Header from "../header/header";
import { useEffect } from "react";


const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };

const Layout = ()=>{
    return(
        <div>
            <ScrollToTop/>
            <Header></Header>
            <Outlet></Outlet>

        </div>
    )
}

export default Layout;
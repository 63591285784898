import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"
import { getFirestore } from "firebase/firestore";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCQyyF8HVcx87haTlhKGYsPrRREHcWDvb0",
    authDomain: "eskeym-ac6c7.firebaseapp.com",
    projectId: "eskeym-ac6c7",
    storageBucket: "eskeym-ac6c7.appspot.com",
    messagingSenderId: "650665690670",
    appId: "1:650665690670:web:4eafb93aa600091f0781bf",
    measurementId: "G-G7Z0PBRPQ7"
  };
 
// Firebase storage reference
const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export default database;
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../../assets/logo.png";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';



const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navbar bg="light" data-bs-theme="light" collapseOnSelect expand="lg" className='navbar_main'>
          <Navbar.Brand href="#/home">
            <img src={logo} alt='logo' className='image_logo_style'/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#/" className='list_navbar'>Home</Nav.Link>
            <Nav.Link href="#/why-eskeym" className='list_navbar'>Why Eskeym</Nav.Link>
            <Nav.Link href="#/careers" className='list_navbar'>Careers and Culture</Nav.Link>
            <NavDropdown title="What we do" className='list_navbar'>
              <NavDropdown.Item href="#/what-we-do/software-development" className='navbar_subitems'>Software Development</NavDropdown.Item>
              <NavDropdown.Item href="#/what-we-do/bigdata"  className='navbar_subitems'>Big Data Solutions</NavDropdown.Item>
              <NavDropdown.Item href="#/what-we-do/product-development" className='navbar_subitems'>Product Development</NavDropdown.Item>
              <NavDropdown.Item href="#/what-we-do/technical-operations" className='navbar_subitems'>Technical Operations</NavDropdown.Item>
              <NavDropdown.Item href="#/what-we-do/operations-management" className='navbar_subitems'>Operations and Management</NavDropdown.Item>
              <NavDropdown.Item href="#/what-we-do/sales-marketing" className='navbar_subitems'>Sales and Marketing</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#/industries" className='list_navbar'>Industry Expertise</Nav.Link>
          </Nav>
          <Nav className='nav-right-main'>
            <Nav.Link className=''>
              <div className="button_main">
                  <button className="btn btn-dark button_get_in_touch" style={{padding: '5px 14px', fontSize: "16px"}} onClick={()=>{
                    navigate("/contact-us")
                }}>Get In Touch</button>
              </div>
            </Nav.Link>
          </Nav>
          </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Header;
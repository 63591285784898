import React, { useState } from "react";
import whyus from "../../assets/whyus.png";
import "./whyus.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDown,
  faAngleUp,
  faAnglesUp,
  faCaretDown,
  faCaretUp,
  faKey,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import resourcePlan from "../../assets/icon-resourceplan.svg";
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";
import { faDev } from "@fortawesome/free-brands-svg-icons";

function Whyus() {
  let navigate = useNavigate();
  const [faqSelected, setFaqSelected] = useState("");
  return (
    <div className="">
      <div className="IntroWhyus row">
        <div className="col-lg-6 col-md-7 col-sm-12 IntroWhyus_submain">
          <div className="whyus_intro_title">ESKEYM CONSULTANCY SERVICES</div>
          <div className="whyus_intro_detail_title">
            Expand your team with confidence
          </div>
          <div className="whyus_intro_detail_text">
            We provide all the services you need to get your business growing
          </div>
          <div className="">
            <button
              className="btn btn-dark button_get_in_touch button_get_in_touch_intro"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Get In Touch
            </button>
          </div>
        </div>
        <div className="col-lg-6 col-md-5 col-sm-12 whyus_intro_image">
          <img src={whyus} width={"80%"} className="image_intro_whyus"></img>
        </div>
      </div>
      <div className="whyus">
        <div className="whyus_title">Why Eskeym?</div>
        <div className="whyus_detail">
          Eskeym can provide you the best solutions to get your business growing
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="whyus_icon_main">
              <FontAwesomeIcon
                icon={faUsers}
                className="whyus_icon"
              ></FontAwesomeIcon>
            </div>
            <div className="whyus_content_detail">
            Happy and <br></br>Engaged Workforce
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="whyus_icon_main">
              <FontAwesomeIcon
                icon={faAnglesUp}
                className="whyus_icon"
              ></FontAwesomeIcon>
            </div>
            <div className="whyus_content_detail">
            Smart <br></br> Solutions for Efficiency
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="whyus_icon_main">
              <FontAwesomeIcon
                icon={faDev}
                className="whyus_icon"
              ></FontAwesomeIcon>
            </div>
            <div className="whyus_content_detail">
            Innovative  <br></br>Technology Integration
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="whyus_icon_main">
              <FontAwesomeIcon
                icon={faKey}
                className="whyus_icon"
              ></FontAwesomeIcon>
            </div>
            <div className="whyus_content_detail">
            Tailored  <br></br>Solutions for Success
            </div>
          </div>
        </div>
      </div>
      <div className="parnership">
        <div className="partnership_title">
          We can be your your partner in growth
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 partnership_content_main">
            <div>
              <img src={resourcePlan} width={"25%"} style={{maxWidth: "100px"}}></img>
            </div>

            <div>
              <div className="partnership_content_title">
              Diverse Expertise
              </div>
              <ul>
                <li className="partnership_content_list">
                Software Development: Tailored solutions using cutting-edge technologies.
                </li>
                <li className="partnership_content_list">
                Big-Data Solutions: Extracting valuable insights for informed decisions.
                </li>
                <li className="partnership_content_list">
                Product Development: Bringing your ideas to market with innovation.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 partnership_content_main">
            <div>
              <img src={resourcePlan} width={"25%"} style={{maxWidth: "100px"}}></img>
            </div>

            <div>
              <div className="partnership_content_title">
              Seamless Technical Operations
              </div>
              <ul>
                <li className="partnership_content_list">
                Proactive maintenance for uninterrupted IT infrastructure.
                </li>
                <li className="partnership_content_list">
                Technical support and monitoring to minimize downtime.
                </li>
                <li className="partnership_content_list">
                Cloud services management for security and scalability.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 partnership_content_main">
            <div>
              <img src={resourcePlan} width={"25%"} style={{maxWidth: "100px"}}></img>
            </div>

            <div>
              <div className="partnership_content_title">
              Comprehensive Operations and Management
              </div>
              <ul>
                <li className="partnership_content_list">
                Streamlined processes for enhanced productivity.
                </li>
                <li className="partnership_content_list">
                Project management and resource allocation expertise.
                </li>
                <li className="partnership_content_list">
                Implementing best practices for operational excellence.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 partnership_content_main">
            <div>
              <img src={resourcePlan} width={"25%"} style={{maxWidth: "100px"}}></img>
            </div>

            <div>
              <div className="partnership_content_title">
              Scalability and Flexibility
              </div>
              <ul>
                <li className="partnership_content_list">
                Solutions designed to scale with your business growth.
                </li>
                <li className="partnership_content_list">
                Flexibility to adapt services based on demand.
                </li>
                <li className="partnership_content_list">
                Staying ahead of technological advancements for ongoing innovation.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 partnership_content_main">
            <div>
              <img src={resourcePlan} width={"25%"} style={{maxWidth: "100px"}}></img>
            </div>
            
            <div>
              <div className="partnership_content_title">
              Proactive Collaboration
              </div>
              <ul>
                <li className="partnership_content_list">
                Transparent and open communication.
                </li>
                <li className="partnership_content_list">
                Regular updates and feedback for mutual understanding.
                </li>
                <li className="partnership_content_list">
                Anticipating challenges and presenting proactive solutions.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 partnership_content_main">
            <div>
              <img src={resourcePlan} width={"25%"} style={{maxWidth: "100px"}}></img>
            </div>

            <div>
              <div className="partnership_content_title">
              Strategic Sales and Marketing
              </div>
              <ul>
                <li className="partnership_content_list">
                Developing effective sales strategies for market reach.
                </li>
                <li className="partnership_content_list">
                Data-driven marketing to engage and convert.
                </li>
                <li className="partnership_content_list">
                Integrating technical solutions with strategic initiatives.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pros">
        <div className="pros_title">
          How we provide better results to our clients?
        </div>
        <div className="pros_subtitle">
        Not bragging, but several factors have contributed to our collaboration with client growth. These represent just a few of the numerous parameters we employ to ensure the happiness and success of our clients.
        </div>
        <div className="row pros_details_main">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="pros_content_head">92%</div>
            <div className="pros_content_detail">
              Problem Resolution Satisfaction <br></br> to our clients
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="pros_content_head">96%</div>
            <div className="pros_content_detail">
            Service Flexibility Satisfaction <br></br> to our clients
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="pros_content_head">90%</div>
            <div className="pros_content_detail">
              Long-Term Success Planning Satisfaction  <br></br> to our clients
            </div>
          </div>
        </div>
      </div>
      {/* <div className='integrations'>

        </div> */}

      <div className="faqs">
        <div className="faqs_title">Frequently asked questions</div>

        <div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "1"
                ? setFaqSelected("")
                : setFaqSelected("1");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">What services does Eskeym Consulting offer?</div>
              <FontAwesomeIcon
                icon={faqSelected == "1" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "1" ? (
              <div className="faqs_content_detail">
                Eskeym Consulting serves as an extended team, specializing in problem-solving and meeting organizational requirements. Our services encompass a range of solutions, from assessment and obstacle identification to management and development.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "2"
                ? setFaqSelected("")
                : setFaqSelected("2");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">How does Eskeym Consulting assess the needs of an organization?</div>
              <FontAwesomeIcon
                icon={faqSelected == "2" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "2" ? (
              <div className="faqs_content_detail">
                Our process begins with a comprehensive assessment of your organization's needs. We engage in a thorough understanding of challenges, allowing us to provide a clear roadmap for issue resolution, cost reduction, and long-term success planning.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "3"
                ? setFaqSelected("")
                : setFaqSelected("3");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">In which sectors does Eskeym Consulting have expertise?</div>
              <FontAwesomeIcon
                icon={faqSelected == "3" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "3" ? (
              <div className="faqs_content_detail">
                Eskeym Consulting has extensive industry experience across various sectors, including but not limited to agriculture, automotive, banking, and more. Our diverse expertise allows us to tailor our services to meet the unique challenges of different industries.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "4"
                ? setFaqSelected("")
                : setFaqSelected("4");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">What makes Eskeym Consulting stand out from other consulting firms?</div>
              <FontAwesomeIcon
                icon={faqSelected == "4" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "4" ? (
              <div className="faqs_content_detail">
                Eskeym Consulting stands out due to its commitment to understanding client challenges deeply. We offer flexibility, a clear problem-solving approach, and a focus on long-term success. Our exceptional team, with years of industry experience, ensures high-quality service delivery.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "5"
                ? setFaqSelected("")
                : setFaqSelected("5");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">How does Eskeym Consulting contribute to cost reduction for organizations?</div>
              <FontAwesomeIcon
                icon={faqSelected == "5" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "5" ? (
              <div className="faqs_content_detail">
                We work collaboratively to identify areas for cost reduction within your organization. By optimizing processes, streamlining operations, and implementing efficient solutions, we help minimize costs without compromising on quality or performance.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "6"
                ? setFaqSelected("")
                : setFaqSelected("6");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">Can Eskeym Consulting provide end-to-end services for organizations?</div>
              <FontAwesomeIcon
                icon={faqSelected == "6" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "6" ? (
              <div className="faqs_content_detail">
                Yes, Eskeym Consulting is equipped to provide end-to-end services, covering everything from management to development. Our comprehensive approach ensures that we can address diverse organizational needs seamlessly.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "7"
                ? setFaqSelected("")
                : setFaqSelected("7");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">What industries has Eskeym Consulting served in the past?</div>
              <FontAwesomeIcon
                icon={faqSelected == "7" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "7" ? (
              <div className="faqs_content_detail">
Eskeym Consulting has a rich history of serving organizations across various industries, including agriculture, automotive, banking, and more. Our experience spans multiple sectors, allowing us to bring valuable insights to each client engagement.              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "8"
                ? setFaqSelected("")
                : setFaqSelected("8");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">How does Eskeym Consulting ensure long-term success for its clients?</div>
              <FontAwesomeIcon
                icon={faqSelected == "8" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "8" ? (
              <div className="faqs_content_detail">
                We focus on strategic planning and collaboration to ensure the long-term success of our clients. By addressing current challenges and anticipating future needs, we help organizations build a sustainable and resilient foundation for growth.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "9"
                ? setFaqSelected("")
                : setFaqSelected("9");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">Can Eskeym Consulting provide references or case studies of previous successful engagements?</div>
              <FontAwesomeIcon
                icon={faqSelected == "9" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "9" ? (
              <div className="faqs_content_detail">
                Yes, we can provide references and case studies showcasing our successful engagements. Feel free to reach out to our team for more detailed insights into how we have assisted organizations in the past.
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="faqs_content_main"
            onClick={() => {
              faqSelected == "10"
                ? setFaqSelected("")
                : setFaqSelected("10");
            }}
          >
            <div className="faqs_content_submain">
              <div className="faqs_content_title">What size of organizations does Eskeym Consulting typically work with?</div>
              <FontAwesomeIcon
                icon={faqSelected == "10" ? faAngleUp : faAngleDown}
              ></FontAwesomeIcon>
            </div>
            {faqSelected == "10" ? (
              <div className="faqs_content_detail">
                Eskeym Consulting is adaptable and works with organizations of various sizes. Whether you are a small business or a large enterprise, our approach is tailored to meet your specific needs and challenges.
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Whyus;

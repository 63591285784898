import React from "react";
import { faLinkedin, faSkype, faTwitter, faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./contactBannerStyles.css";

function ContactBanner() {
  return (
    <div className="contact_banner_main">
      <div className ="icon_main">
        <Link
          to="https://www.linkedin.com/company/eskeym-consulting-services/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            style={{ color: "white", fontSize: "24px" }}
          />
        </Link>
      </div>
      <div className ="icon_main">
        <Link
          to="https://twitter.com/eskeym_services"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faXTwitter}
            style={{ color: "white", fontSize: "24px" }}
          />
        </Link>
      </div>
      <div className ="icon_main">
        <Link
          to="skype:live:.cid.ce463c9f1bcaa2cf?chat"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faSkype}
            style={{ color: "white", fontSize: "24px" }}
          />
        </Link>
      </div>
      <div className ="icon_main">
        <Link
          to="https://web.whatsapp.com/send?phone=+919354584782"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faWhatsapp}
            style={{ color: "white", fontSize: "24px" }}
          />
        </Link>
      </div>
    </div>
  );
}

export default ContactBanner;

import React from "react";
import "./footer.css";
import logo from "./../../assets/logo.png";
import { Link, Route, Router, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faSkype, faTwitter, faWhatsapp, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import ContactBanner from "../contactBanner/contactBanner";

function Footer(props) {
  let navigate = useNavigate();

  return (
    <div className="footer_main">
      <ContactBanner/>
      {props.isGetInTouch == false ? (
        ""
      ) : (
        <div className="footer_title_main">
          <div className="footer_try_us">
            Try the #1 organization in <br></br>consulting and services
          </div>
          <div className="button_main">
            <button
              className="btn btn-light button_get_in_touch"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Get In Touch
            </button>
          </div>
        </div>
      )}
      <div className="footer_submain">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div>
              <img
                src={logo}
                width={"60%"}
                style={{
                  backgroundColor: "white",
                  padding: "15px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/home")}
              ></img>
              <div className="footer_detail_logo_detail_title">
                Learn How Our Solutions Can Help You
              </div>
              <div className="footer_detail_logo_detail">
                Eskeym focuses on providing business-driven and scalable
                solutions to help you run your business more efficiently.
                Contact us to drive your business forward.
              </div>
            </div>
            <div className="footer_brands">
              <div style={{paddingRight: "15px"}}>
                <Link to="https://www.linkedin.com/company/eskeym-consulting-services/" target="_blank" rel="noopener noreferrer" >
                <FontAwesomeIcon icon={faLinkedin} style={{ color: "white", fontSize: '35px' }} />
                </Link>
              </div>
              <div style={{paddingRight: "15px"}}>
                <Link to="https://twitter.com/eskeym_services" target="_blank" rel="noopener noreferrer" >
                <FontAwesomeIcon icon={faXTwitter} style={{ color: "white", fontSize: '35px' }} />
                </Link>
              </div>
              <div style={{paddingRight: "15px"}}>
                <Link to="https://www.linkedin.com/company/eskeym-consulting-services/" target="_blank" rel="noopener noreferrer" >
                <FontAwesomeIcon icon={faSkype} style={{ color: "white", fontSize: '35px' }} />
                </Link>
              </div>
              <div style={{paddingRight: "15px"}}>
                <Link to="https://web.whatsapp.com/send?phone=+919354584782" target="_blank" rel="noopener noreferrer" >
                <FontAwesomeIcon icon={faWhatsapp} style={{ color: "white", fontSize: '35px' }} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="footer_detail_title">Quick Links</div>
            <div>
              <div
                className="footer_detail_text"
                onClick={() => navigate("/home")}
              >
                Home
              </div>
              <div
                className="footer_detail_text"
                onClick={() => navigate("/why-eskeym")}
              >
                Why Eskeym
              </div>
              <div
                className="footer_detail_text"
                onClick={() => navigate("/careers")}
              >
                Careers
              </div>
              <div
                className="footer_detail_text"
                onClick={() => navigate("/industries")}
              >
                Industries
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6">
            <div className="footer_detail_title">What we do</div>
            <div>
              <div className="footer_detail_text">Software Development</div>
              <div className="footer_detail_text">Big-Data Solutions</div>
              <div className="footer_detail_text">Product Development</div>
              <div className="footer_detail_text">Technical Operations</div>
              <div className="footer_detail_text">
                Operations and Management
              </div>
              <div className="footer_detail_text">Sales and Marketing</div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="footer_detail_title">Contact Us</div>
            <div>
              <div
                className="footer_detail_text"
                onClick={() => navigate("/contact-us")}
              >
                Get In Touch
              </div>
              <div
                className="footer_detail_text"
                onClick={() => navigate("/contact-us")}
              >
                info@eskeym.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

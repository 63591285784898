import { useState, useEffect } from "react";
import "./main.css";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import httpService from "./services/http.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/layout";
import Home from "./components/Home/home";
import Whyus from "./components/whyus/whyus";
import Careers from "./components/careers/careers";
import GetInTouch from "./components/getintouch/getInTouch";
import Industries from "./components/industries/industries";
import WhatWeDo from "./components/whatWeDo/whatWeDo";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="why-eskeym" element={<Whyus />} />
            <Route path="careers" element={<Careers />} />
            <Route path="contact-us" element={<GetInTouch />} />
            <Route path="industries" element={<Industries />} />
            <Route path="what-we-do">
              <Route path="software-development" element={<WhatWeDo type = {"software"}/>} />
              <Route path="product-development" element={<WhatWeDo type = {"product"}/>} />
              <Route path="technical-operations" element={<WhatWeDo type = {"techops"}/>} />
              <Route path="sales-marketing" element={<WhatWeDo type = {"sales"}/>} />
              <Route path="operations-management" element={<WhatWeDo type = {"ops"}/>} />
              <Route path="bigdata" element={<WhatWeDo type = {"bigdata"}/>} />

            </Route>
            <Route path="*" element = {
              <Navigate to="/"/>
            } />
          </Route>
          <Route path="*" element = {
              <Navigate to="/"/>
            } />
        </Routes>
      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
